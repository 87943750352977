import React from 'react'
import { useTranslation } from 'react-i18next'
import TestFreak from '../third-party/testfreak/testfreak'

const ProductReview = () => {
    const { t } = useTranslation()
    return (
        <section className="media-banner">
            <div className="grid-container">
                <div className="grid__row">
                    <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
                        <TestFreak props={{ type: 'reviews', title: t('reviews.title'), productId: "A-420-OR" }} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductReview